import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Em, Section, Span } from "@quarkly/widgets";
import QuarklycommunityKitNetlifyForm from "./QuarklycommunityKitNetlifyForm";
const defaultProps = {
	"md-padding": "30px 0 30px 0",
	"padding": "80px 0 80px 0",
	"border-color": "rgba(227, 255, 57, 0.31)",
	"border-width": "0.5px 0 0 0",
	"border-style": "solid",
	"background": "--color-darkL1"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"md-width": "100%",
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"width": "100%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"md-text-align": "left",
			"margin": "0px 0px 26px 0px",
			"color": "--light",
			"lg-font": "700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif",
			"text-align": "center",
			"font": "--headline5",
			"text-transform": "uppercase",
			"children": "HIGH QUALITY, RELIABILITY AND SPEED"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-margin": "24px 0px 16px 0px",
			"margin": "28px 0px 1rem 0px",
			"text-transform": "uppercase",
			"justify-content": "center"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"border-style": "solid",
			"border-color": "rgba(255, 255, 255, 0)",
			"href": "/index",
			"text-decoration-line": "initial",
			"margin": "0px 32px 0px 0px",
			"hover-color": "--green",
			"lg-margin": "0px 16px 0px 0px",
			"color": "--light",
			"font": "--base",
			"padding": "6px 0px 6px 0px",
			"hover-border-color": "--color-green",
			"border-width": "1px 0px 0px 0px",
			"lg-font": "normal 400 16px/1.5 \"Varta\", sans-serif",
			"children": "HOMEPAGE"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"hover-border-color": "--color-green",
			"border-style": "solid",
			"font": "--base",
			"padding": "6px 0px 6px 0px",
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"border-width": "1px 0px 0px 0px",
			"lg-font": "normal 400 16px/1.5 \"Varta\", sans-serif",
			"hover-color": "--green",
			"border-color": "rgba(255, 255, 255, 0)",
			"lg-margin": "0px 16px 0px 0px",
			"text-decoration-line": "initial",
			"color": "--light",
			"margin": "0px 32px 0px 0px",
			"href": "/menu",
			"children": "SERVICES"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"hover-color": "--green",
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"padding": "6px 0px 6px 0px",
			"hover-border-color": "--color-green",
			"border-color": "rgba(255, 255, 255, 0)",
			"margin": "0px 32px 0px 0px",
			"border-style": "solid",
			"lg-margin": "0px 16px 0px 0px",
			"href": "/contacts",
			"text-decoration-line": "initial",
			"color": "--light",
			"font": "--base",
			"border-width": "1px 0px 0px 0px",
			"lg-font": "normal 400 16px/1.5 \"Varta\", sans-serif",
			"children": "CONTACTS"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-margin": "28px 0px 0px 0px",
			"margin": "28px 0px 0px 0",
			"justify-content": "center",
			"text-align": "center"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline5",
			"color": "--green",
			"children": <>
				2/22 Dargie Ct, Dallas VIC 3047, Australia
				<br />
				<br />
				+61393097933
			</>
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"width": "40%",
			"md-width": "100%",
			"md-margin": "24px 0px 0px 0px",
			"empty-min-width": "64px",
			"display": "none"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"md-text-align": "left",
			"margin": "0px 0px 26px 0px",
			"color": "--light",
			"font": "--headline4",
			"text-align": "right",
			"lg-font": "700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif",
			"children": "Спробуйте перукарське мистецтво в UrbanTrim"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"md-text-align": "left",
			"margin": "0px 0px 26px 0px",
			"color": "--lightD2",
			"font": "--base",
			"text-align": "right",
			"lg-font": "700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif",
			"children": "Ваш стиль, довершений"
		}
	},
	"quarklycommunityKitNetlifyForm": {
		"kind": "QuarklycommunityKitNetlifyForm",
		"props": {
			"display": "flex",
			"align-items": "center",
			"justify-content": "flex-end",
			"md-justify-content": "flex-start"
		}
	},
	"quarklycommunityKitNetlifyFormOverride": {
		"kind": "Override",
		"props": {
			"slot": "Form",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Box {...override("box1")}>
				<Link {...override("link")} />
				<Link {...override("link1")} />
				<Link {...override("link2")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text1")} />
			</Box>
		</Box>
		<Box {...override("box3")}>
			<Text {...override("text2")} />
			<Text {...override("text3")} />
			<QuarklycommunityKitNetlifyForm {...override("quarklycommunityKitNetlifyForm")}>
				<Override {...override("quarklycommunityKitNetlifyFormOverride")} />
			</QuarklycommunityKitNetlifyForm>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;